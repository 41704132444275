<template>
	<div class="block-inner">
		<header v-html="question"></header>
		<ul :class="columns" @input="save">
			<li v-for="option in options" :key="option.id">
				<BoolInput :name="optionGroup" :radio="!block.meta.allowMultiple" :label="option.label" :data-option="option.id" :checked="hasAnswerOption(option.id)" />
			</li>
		</ul>
	</div>
</template>

<script>
	import BoolInput from '../BoolInput';
	import {shuffleArray} from '../../inc/utils';
	import answerMixin from '../../mixins/answerMixin';
	import {jsonToHtml} from '../../inc/text';

	export default {
		components: {
			BoolInput
		},
		mixins: [answerMixin],
		props: {
			block: {
				type: Object,
				required: true
			}
		},
		computed: {
			optionGroup() {
				const keys = Object.keys(this.block.options);

				return keys.pop();
			},
			question() {
				return jsonToHtml(this.block.content);
			},
			options() {
				if(this.optionGroup) {
					let ops = JSON.parse(JSON.stringify(this.block.options[this.optionGroup]));
					const method = this.block.meta.sortOptions;

					if(method === 'random') {
						ops = shuffleArray(ops);
					}
					else if(method === 'alpha') {
						ops.sort((a, b) => {
							if(a.label < b.label) {
								return -1;
							}
							if(a.label > b.label) {
								return 1;
							}

							return 0;
						});
					}

					return ops;
				}

				return [];
			},
			columns() {
				return this.options.length >= 5 ? 'columns' : '';
			}
		},
		methods: {
			save(e) {
				const checked = e.target.closest('ul').querySelectorAll('input:checked');
				const options = Array.from(checked, input => ({
					id: input.closest('[data-option]').dataset.option,
					label: input.closest('[data-option]').textContent
				}));

				this.sendAnswerOptions(options, this.optionGroup);
			}
		}
	};
</script>

<style lang="scss" scoped>
	header::v-deep {
		b {
			font-weight: $font__bold;
		}
	}
	ul {
		list-style-type: none;
		padding-left: 0;
	}

	.bool-input {
		margin-bottom: 1rem;
		cursor: pointer;

		&:hover {
			color: $color__link;
		}
	}

	@include breakpoint-min('medium') {
		ul.columns {
			columns: 2;
		}
	}

	@include breakpoint-min('large') {
		ul.columns {
			columns: 3;
		}
	}
</style>